<template>
  <div class="nav-search-ui">
    <div
      class="nav-search-ui__container"
      ref="container"
      :class="{ 'nav-search-ui__container--active': active }"
    >
      <div class="nav-search-ui__content" ref="scrollContainer">
        <ais-instant-search
          :search-client="searchClient"
          :index-name="algoliaIndex('products')"
        >
          <ais-configure :query="query" :hitsPerPage="5" />
          <NavigationSearchInput
            v-if="active"
            class="nav-search-ui__input"
            v-model="query"
            ref="searchInput"
            @submit="handleInputSubmit"
          />
          <div class="nav-search-ui__results-container">
            <TransitionResults>
              <div
                v-if="query.length > 1"
                class="text-commerce-heading-3 text--clr-black mt-24"
              >
                {{
                  UI_ELEMENT("search_results_counter", [
                    {
                      tag: "TOTAL_RESULTS",
                      value: totalResults
                    },
                    {
                      tag: "UNIT_SEARCH_RESULT",
                      value: UI_UNIT("unit_search_result", totalResults)
                    }
                  ])
                }}
              </div>
            </TransitionResults>
            <!-- PRODUCTS - default index, declared above -->
            <NavSearchResultsProducts
              :key="`results_products`"
              :query="query"
              @results="hits => resultsHandler(hits, 'products')"
            />

            <!-- This is a bit static, but probably best for maintaining clarity of the results architecture -->
            <!-- SETS -->
            <ais-index :index-name="algoliaIndex('sets')">
              <NavSearchResultsProducts
                :key="`results_sets`"
                sets
                :query="query"
                @results="hits => resultsHandler(hits, 'sets')"
              />
            </ais-index>

            <!-- COLLECTIONS -->
            <ais-index :index-name="algoliaIndex('collections')">
              <NavSearchResultsCollections
                :key="`results_collections`"
                :query="query"
                @results="hits => resultsHandler(hits, 'collections')"
              />
            </ais-index>

            <!-- PAGES -->
            <ais-index :index-name="algoliaIndex('pages')">
              <NavSearchResultsPages
                :key="`results_pages`"
                :query="query"
                @results="hits => resultsHandler(hits, 'pages')"
              />
            </ais-index>
            <NavSearchResultsSuggestions :key="`results_suggestions`" />
          </div>
        </ais-instant-search>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue"

import { mapGetters, mapState } from "vuex"

import AlgoliaAPI from "@/integration/AlgoliaAPI"

import { AisInstantSearch, AisConfigure, AisIndex } from "vue-instantsearch"

import NavigationSearchInput from "./NavigationSearchInput.vue"
import NavSearchResultsProducts from "./NavSearchResultsProducts.vue"
import NavSearchResultsCollections from "./NavSearchResultsCollections.vue"
import NavSearchResultsPages from "./NavSearchResultsPages.vue"
import NavSearchResultsSuggestions from "./NavSearchResultsSuggestions.vue"

import TransitionResults from "./NavigationSearchTransition_Results.vue"

import { lockScroll, unlockScroll } from "@/helpers/ScrollLock"

export default {
  props: {
    active: Boolean
  },

  components: {
    AisInstantSearch,
    AisConfigure,
    AisIndex,
    NavigationSearchInput,
    NavSearchResultsProducts,
    NavSearchResultsCollections,
    NavSearchResultsPages,
    NavSearchResultsSuggestions,
    TransitionResults
  },

  data: () => ({
    searchClient: AlgoliaAPI.searchClient,
    query: "",
    resultsLengths: {},
    totalResults: 0,
    scrollableHeight: 0
  }),

  mounted() {
    this.$refs["container"].addEventListener(
      "transitionend",
      this.onSlideComplete
    )
  },

  beforeDestroy() {
    this.$refs["container"].removeEventListener(
      "transitionend",
      this.onSlideComplete
    )
  },

  methods: {
    resultsHandler(hits, type) {
      // TODO - without this check guard it spirals into an infinte death loop. Find out why
      // Probably because updating the data forces a re-render somewhere.
      // possibly related to https://github.com/algolia/vue-instantsearch/issues/663#issuecomment-489695804
      if (hits.length != this.resultsLengths[type]) {
        Vue.set(this.resultsLengths, type, hits.length || 0)
      }
    },

    algoliaIndex(type) {
      return process.env[`VUE_APP_ALGOLIA_INDEX_${type}`]
    },

    onSlideComplete(event) {
      console.log(event.propertyName)
      if (this.active && event.propertyName == "transform") {
        this.$refs["searchInput"].doFocus()
      }
    },

    handleInputSubmit(/* val */) {
      // TODO: Here we could add val to recent searches, stored in localStorage for this user
    }
  },

  computed: {
    ...mapState("locale", ["locale"]),
    ...mapState("scroll", ["viewportHeight"]),
    ...mapGetters("breakpoints", [
      "GET_IS_ABOVE_BREAKPOINT",
      "GET_IS_BELOW_BREAKPOINT"
    ]),

    getTotalResults() {
      return Object.values(this.resultsLengths).reduce((a, b) => a + b, 0)
    }
  },

  watch: {
    active(newVal) {
      if (!newVal) {
        this.query = ""
        unlockScroll(this.$refs["scrollContainer"])
      } else {
        lockScroll(this.$refs["scrollContainer"])
      }
    },

    resultsLengths: {
      handler(newVal) {
        this.totalResults = Object.values(newVal).reduce((a, b) => a + b, 0)
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
.nav-search-ui {
  position: absolute;
  width: 100%;
  max-width: 450px;
  // overflow: hidden;
  right: 0;
  pointer-events: none;

  top: 0;
  height: 100%;

  &__container {
    position: relative;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%;
    background-color: getcolour(bleach_white);
    transform: translateX(0);
    transition: transform 0.2s ease;

    &--active {
      transform: translateX(-100%);
    }
  }

  &__content {
    position: relative;
    width: 100%;
    height: auto;
    max-height: 100%;
    pointer-events: all;
    overflow: hidden;
    overflow-y: auto;
    padding: 0 24px 24px;

    @include media-breakpoint-up(md) {
      padding: 0 40px 24px;
    }
  }

  &__input {
    padding-top: 24px;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: getcolour(bleach_white);
    touch-action: none;
  }
}
</style>
