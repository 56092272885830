<template>
  <form class="nav-search-input" @submit.prevent="">
    <input
      id="searchinput"
      tabindex="0"
      class="nav-search-input__input text-commerce-forms-select"
      :value="value"
      @input="$emit('input', $event.target.value)"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
      @keyup.enter="handleSubmit"
      :placeholder="UI_ELEMENT('search_placeholder')"
      type="text"
      ref="input"
    />
  </form>
</template>

<script>
export default {
  props: {
    value: String
  },

  mounted() {},

  methods: {
    doFocus() {
      this.debug.log("SHOULD FOCUS!", this.$refs.input)
      this.$nextTick(() => {
        this.$refs.input.focus()
      })
    },

    handleSubmit(evt) {
      this.$emit("submit", this.value)
      // TODO: For some reason this doesn't blur if you use the target... why?!
      if (evt.target === document.activeElement) document.activeElement.blur()
    }
  }
}
</script>

<style lang="scss">
$input-inset: 8px;

.nav-search-input {
  &__input {
    border: 1px solid transparent;
    background: unset;
    background-color: getcolour(bleach_white);
    width: calc(100% + (#{$input-inset} * 2));
    border-bottom: 1px solid rgba($bleach-black, 0.25);
    transition: border 0.1s linear;
    padding: $input-inset $input-inset 1em;
    margin: (-$input-inset) (-$input-inset) 0;
    user-select: auto;

    &::placeholder {
      color: $bleach-grey-80;
      font-weight: normal;
    }

    &:focus {
      border: 1px solid rgba(getcolour(bleach_peach), 0);
      outline: unset;
      border-bottom: 1px solid $bleach-black;

      body:not(.mouse-nav) & {
        border: 1px solid getcolour(bleach_peach);
      }
    }
  }
}
</style>
