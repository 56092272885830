<script>
export default {
  name: "Results",
  functional: true,
  props: {
    group: Boolean
  },
  render(createElement, context) {
    const data = {
      props: {
        name: `results`,
        tag: `div`,
        appear: true
      },

      on: {
        afterEnter(el) {
          el.style.height = "auto"
        },
        enter(el) {
          const { width } = window.getComputedStyle(el)

          el.style.width = width
          el.style.position = "absolute"
          el.style.visibilty = "hidden"
          el.style.height = "auto"

          const { height } = window.getComputedStyle(el)

          el.style.width = null
          el.style.position = null
          el.style.visibilty = null
          el.style.height = 0

          window.getComputedStyle(el).height

          requestAnimationFrame(() => {
            el.style.height = height
          })
        },

        leave(el) {
          const { height } = window.getComputedStyle(el)

          el.style.height = height

          getComputedStyle(el).height

          requestAnimationFrame(() => {
            el.style.height = 0
          })
        }
      }
    }
    return createElement(
      context.props.group ? "transition-group" : "transition",
      data,
      context.children
    )
  }
}
</script>

<style lang="scss" scoped>
* {
  will-change: height, margin, opacity;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}
</style>

<style lang="scss">
.results-enter-active {
  transition: opacity 0.1s linear 0.2s, height 0.3s ease-in-out,
    margin 0.2s ease-in-out;
  overflow: hidden;
}

.results-leave-active {
  transition: opacity 0.1s linear, height 0.3s ease-in-out,
    margin 0.2s ease-in-out;
  overflow: hidden;
}

.results-enter,
.results-leave-to {
  opacity: 0;
  height: 0;
  margin: 0;
}
</style>
